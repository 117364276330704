import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import translationEng from "./locales/en/translation.json";
import translationEs from "./locales/es/translation.json";
i18n
	.use(XHR)
	.use(LanguageDetector)
	.init({
		debug: true,
		lng:"en",
		fallbackLng: "en", // use en if detected lng is not available
		//keySeparator: true, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false // react already safes from xss
		},
		resources: {
			en: {
				translations: translationEng
			},
			es: {
				translations: translationEs
			}
		},
		// have a common namespace used around the full app
		ns: ["translations"],
		defaultNS: "translations",
		react: {
		wait: false,
		bindI18n: 'languageChanged loaded',
		bindStore: 'added removed',
		nsMode: 'default'
	}
	});

export default i18n;
