import React from 'react';
class Loader extends React.Component {
	render() {
		return (
				<div className="cont-loader">
				<svg id="loader-svg" xmlns="http://www.w3.org/2000/svg"   width="146" height="72" viewBox="0 0 146 72">
					<defs>
						<filter id="glow_rec-fondo" height="300%" width="300%" x="-75%" y="-75%">
							<feMorphology operator="dilate" radius="2" in="SourceAlpha" result="thicken" />
							<feGaussianBlur in="thicken" stdDeviation="15" result="blurred" />
							<feFlood floodColor="#2EF0FF" result="glowColor" />
							<feComposite in="glowColor" in2="blurred" operator="in" result="softGlow_colored" />
							<feMerge>
								<feMergeNode in="softGlow_colored"/>
								<feMergeNode in="SourceGraphic"/>
							</feMerge>
						</filter>
						<filter id="glow_recs" height="300%" width="300%" x="-75%" y="-75%">
							<feMorphology operator="dilate" radius="2" in="SourceAlpha" result="thicken" />
							<feGaussianBlur in="thicken" stdDeviation="4" result="blurred" />
							<feFlood floodColor="#2EF0FF" result="glowColor" />
							<feComposite in="glowColor" in2="blurred" operator="in" result="softGlow_colored" />
							<feMerge>
								<feMergeNode in="softGlow_colored"/>
								<feMergeNode in="SourceGraphic"/>
							</feMerge>
						</filter>
					</defs>
					<rect id="rec-fondo" filter="url(#glow_rec-fondo)" x="57.2" y="17.2" width="72" height="72" transform="translate(126.2 -57.2) rotate(90)" fill="#80ebfa" opacity="0.28"/>
					<line x1="124.4" y1="36.4" x2="21.8" y2="35.9" fill="#80ebfa" stroke="#80ebfa" strokeMiterlimit="10" strokeWidth="2"/>
					<rect id="rec" filter="url(#glow_recs)" x="64.5" y="27.5" width="17" height="17" fill="#80ebfa" stroke="#80ebfa" strokeMiterlimit="10" strokeWidth="5">
					</rect>
					<rect id="rec-l" x="1" y="33" width="6" height="6" fill="#80ebfa" stroke="none" strokeMiterlimit="10" strokeWidth="2">
					</rect>
					<rect id="rec-r" x="139" y="33" width="6" height="6" fill="#80ebfa" stroke="none" strokeMiterlimit="10" strokeWidth="2">
					</rect>
				</svg>
				</div>
			);
	}
}
export default Loader;