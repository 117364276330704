import React from 'react';
import './Contact.css'
import { withTranslation} from "react-i18next";
import {TweenMax} from 'gsap';
import { TimelineMax as Timeline, Power1} from 'gsap';
class Contact extends React.Component {

constructor(props){
  super(props);

  this.state={
    name:'',
    email:'',
    message:'',
    invalid:false,
    displayErrors:false,
    contactStage:"name",
  }
  this.refContactCont = React.createRef();
  this.handleNext = this.handleNext.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSuccess = this.handleSuccess.bind(this);
}
handleChange = event =>{

event.preventDefault();
if(!event.currentTarget.checkValidity()){

        this.setState({
          invalid: true,
          displayErrors: true });
          return;
      }

      else{
        this.setState({ [event.target.name]:event.target.value ,displayErrors: false})
       
      }


}

handleNext(event) {

  const node = this.refContactCont.current;
  const name = node.querySelector('#name');
  const mail = node.querySelector('#mail');
  const message = node.querySelector('#message');
  const mailStage = node.querySelector('#stage-email');
  const messageStage = node.querySelector('#stage-message');
  const sendStage = node.querySelector('#stage-send');
  const progressStage = node.querySelectorAll('.form-progress-active')
  const selector = node.querySelector('.form-progress-selector');
  const next = node.querySelector('.button-next');
 
    if(this.state.contactStage === "name"){

      if(!name.checkValidity()){

        this.setState({
          invalid: true,
          displayErrors: true });
          return;
      }

      for (var i = 0; i < progressStage.length; i++) {
         progressStage[i].className =  progressStage[i].className.replace("form-progress-active", "");
      }
     
      this.setState({contactStage: "email",invalid: false,displayErrors: false })
      mailStage.className += " form-progress-active";
      TweenMax.to(selector,0.5,{left:"25%"});

      
    }
    else if(this.state.contactStage === "email" ){

      if(!mail.checkValidity()){
          this.setState({
          invalid: true,
          displayErrors: true });
          return;
      }

      for (var h = 0; h < progressStage.length; h++) {
         progressStage[h].className =  progressStage[h].className.replace("form-progress-active", "");
      }

      this.setState({contactStage: "message",invalid: false,displayErrors: false})
      messageStage.className += " form-progress-active";
      TweenMax.to(selector,0.5,{left:"50%"});
    }

    else if(this.state.contactStage === "message" ){

      if(!message.checkValidity()){
          this.setState({
          invalid: true,
          displayErrors: true });
          return;
      }

      for (var g = 0; g < progressStage.length; g++) {
         progressStage[g].className =  progressStage[g].className.replace("form-progress-active", "");
      }
      this.setState({contactStage: "send",invalid: false,displayErrors: false})
      sendStage.className += " form-progress-active";
      TweenMax.to(selector,0.5,{left:"100%"});
      TweenMax.to(next,0.1,{opacity:0});
    }

 

}


handleSubmit(event) {

event.preventDefault();
//Para que funcione el intro con el boton next antes de validar
if(this.state.name === '' || this.state.email === '' || this.state.message === '' || this.state.contactStage === "message"){
  this.handleNext();
}

else if(this.state.contactStage === "send"){

  if (!event.target.checkValidity()) {
  this.setState({
      invalid: true,
      displayErrors: true });

    return;
  }

this.setState({ displayErrors: false });

const data = new FormData(event.target);
const url = '../ajax/smtp_contacto.php';

fetch(url, {

method: 'POST', // or ‘PUT’
body: data,
mode: 'no-cors'

 }).then(function(response) {
    return response.text();

}).then(
    this.handleSuccess()
      
).catch(function(err) {
        
    alert("no send")
});

}


}

handleSuccess(){

const node = this.refContactCont.current;
const buttonSend = node.querySelector('button');
const sendingLoader = node.querySelector('.sending-loader');
const sendingConfirm = node.querySelector('#sending-confirm');

const timeline = new Timeline({ paused: true });

timeline.to(buttonSend, 0.3, {opacity:0.3,ease: Power1.easeInOut})
        .to(sendingLoader, 1, {width:"70%",ease: Power1.easeInOut})
        .to(buttonSend, 0.3, {opacity:0,pointerEvents:"none",ease: Power1.easeInOut})
        .to(sendingLoader, 0.3, {opacity:0,ease: Power1.easeInOut})

        .to(sendingConfirm, 1, {opacity:1,ease: Power1.easeInOut})
        ;

timeline.play();
  
}
render(){ 
  const { t } = this.props;
  const { displayErrors, contactStage } = this.state;
  return (
    <div className="view contact-cont" ref={this.refContactCont}>
    <div className="contact-super-subcont">
      <div className="contact-section1">
        <div className="contact-header-cont">
          <div className="contact-header">{t("Let`s get in touch")}</div>
        </div>
        <div className="contact-email-cont">
        {t("TELL ME ABOUT YOUR PROJECT")}
        <div className="form-progress-cont">
             <div className="form-progress-selector "></div>
                <div id="stage-name" className="form-progress-stage form-progress-active">{t("Name")}</div>
               <div id="stage-email" className="form-progress-stage">E-mail</div>
               <div id="stage-message" className="form-progress-stage">{t("Message")}</div>
               <div id="stage-send" className="form-progress-stage">{t("Send")}</div>
          </div>
        <form id="form" onSubmit={this.handleSubmit} noValidate className={displayErrors ? 'displayErrors' : ''}>
           <div className="form-elements cont-inputs">
              <input id="name"
                     placeholder={t("Name")}
                     name="name"
                     type="text"
                     data-parse="uppercase"
                     required
                     onChange={this.handleChange}
                     className={contactStage !== "name" ? 'input-disabled' : 'input-active'}/>

              <input id="mail"
                     placeholder="E-mail"
                     name="email"
                     type="email"
                     required
                     onChange={this.handleChange}
                     className={contactStage !== "email" ? 'input-disabled' : 'input-active'} />
              <input id="message"
                     placeholder={t("Message")}
                     name="message"
                     type="text"
                     required
                     onChange={this.handleChange}
                     className={contactStage !== "message" ? 'input-disabled' : 'input-active'}/>

            </div>

            <div className="form-elements cont-button-next">
              <div className={displayErrors ? 'button-next-error' : 'button-next'} onClick={this.handleNext}>{t("Next")}</div>
            </div>

             <button className={contactStage !== "send" ? 'input-disabled' : ''}>{t("Send data!")}</button>
             
             <div className="sending-loader"></div>
             <div id="sending-confirm">{t("Thanks! I will get back to you asap")}</div>

      </form>
          <div className="contact-email-header ">{t("Or just e-mail me")}</div>
          <a className="contact-email" href="mailto:info@diegopiscitelli.com">info<b>@diegopiscitelli.com</b></a>
           <div className="contact-info">
           <div className="contact-info-header">{t("Personal Info")}</div>
            <ul>
              <li>Diego Piscitelli</li>
              <li>(+34) 604 97 86 91</li>
              <li>Skype: depiscitelli</li>
            </ul>
          </div>
           <div className="contact-redes">
            <div className="contact-redes-header">{t("Connect")}</div>
            <div className="redes"><a href="https://www.linkedin.com/in/diegopiscitelli/" className="redes-button" >Linkedin</a></div>
            <div className="redes"><a href="https://www.behance.net/diegopiscitelli" className="redes-button" >Behance</a></div>
            <div className="redes"><a href="https://www.flickr.com/photos/piscitelli_photography/" className="redes-button" >Flicker</a></div>
          </div>

          <div className="copyright">©2024 PiscitelliDesign</div>
          
        </div>


        </div>
    </div>

        
</div>
    )
}
}


export default (withTranslation("translations")(Contact));


