import React from 'react';
import './projects.css'
import data from '../data/data'
import { NavLink } from 'react-router-dom';
import { withTranslation} from "react-i18next";

class Logos extends React.Component {




render(){
const { t } = this.props;
const category = this.props.category;
const index = this.props.index;
const project = data[category][index];

const galery = project.expandImgArray.map(item => <div key={item} className="cont-img-galery"><img src={"../" + item} alt="Logos" className="img-galery logos"></img></div> );
  
  return (
    <div className="work-page-cont ">
    <NavLink className="work-page-button "  strict exact to={"/works?category="+category +"&project="+index} >{t("Back")}</NavLink>
      
        <div className="work-page-subcont light">
       
       
        </div>

        <div className="work-page-project light">

              <div className="project-header-cont">
                    <div className="project-header-subcont">
                        <p className="project-header">{t("Project")}</p>
                        <p className="project-description-text">{t("Logotypes for several projects")}</p>
                    </div>
                    
              </div>

              <div className="project-bullets">
                  <div className="project-bullets-header">{t("Tools")}:</div>
                   <ul>
                        <li className='especial-bullets'>Photoshop</li>
                        <li className='especial-bullets'>Ilustrator</li>
                  </ul>
              </div>

              

        </div>

        <div className="work-page-galery light">

         {galery}

        </div>
        <NavLink className="work-page-button "  strict exact to={"/works?category="+category +"&project="+index} >{t("Back")}</NavLink>

      </div>
		)
}
}



export default (withTranslation("translations")(Logos));