import React from 'react';
import './Experience.css'
import Marcador from '../Media/img/marcador_experience.svg';
import arrowTitles from '../Media/img/arrow_titles_experience.svg'
import IcoExperience from '../Media/img/ico_experience.svg';
import IcoSpanish from '../Media/img/ico_spanish.svg';
import IcoEnglish from '../Media/img/ico_english.svg';

import IcoEducation from '../Media/img/ico_education.svg';
import IcoLang from '../Media/img/ico_lang.svg';
import IcoInterests from '../Media/img/ico_interests.svg';
import {TweenMax} from 'gsap';
import { withTranslation } from "react-i18next"; 


class Experience extends React.Component {

  constructor(props){
		super(props);
		this.handleResumeDownload = this.handleResumeDownload.bind(this);
		this.handleResumeDownloadOut = this.handleResumeDownloadOut.bind(this);
		this.refExperienceCont = React.createRef();
	}

	componentDidMount() {
		const node = this.refExperienceCont.current;
		const puntos = node.querySelectorAll('.puntos');

		for (var i = 0; i < puntos.length; i++ ) {  
			var width = puntos[i].parentNode.dataset.porcentaje; 
			TweenMax.set(puntos[i],{width:width});
		}

		setTimeout(
			function(){
			TweenMax.staggerFrom(puntos,1,{width:"0%"},0.1)}
			,1500
		)
	}

handleResumeDownload() {
  const node = this.refExperienceCont.current;
  const download = node.querySelector('.resume-download-subcont');
  const downloadText = node.querySelector('.download-text');
  TweenMax.to(download,0.3,{opacity:1,top:10});
  TweenMax.to(downloadText,0.3,{opacity:0,bottom:10});
}

handleResumeDownloadOut() {
  const node = this.refExperienceCont.current;
  const download = node.querySelector('.resume-download-subcont');
  const downloadText = node.querySelector('.download-text');
  TweenMax.to(download,0.3,{opacity:0,top:30});
  TweenMax.to(downloadText,0.3,{opacity:1,bottom:0});
}

render() {
	const { t } = this.props;
	const puntos = <div  className="puntos"></div>;

	return (
		<div className="experience-cont" ref={this.refExperienceCont}>
				<div className="header-sections-experience">{t("Habilities / Software")}</div>

			<div className="habilidades-cont">
				
				<div className="habilidades-sub-cont">
					<div className="exp-headers-cont">
						<div className="exp-header-elements"><img className="marcador" alt="" src={Marcador}></img></div>
						<div className="exp-header-elements">Tech Stack</div>
					</div>
					<div className="puntuaje">
						<div className="puntuaje-subconts"><div className="titulos-prueba">JavaScript</div><div className="cont-puntos puntos-web" data-porcentaje="100%"> {puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">Vue</div><div className="cont-puntos puntos-web" data-porcentaje="100%">{puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">React</div><div className="cont-puntos puntos-web" data-porcentaje="75%">{puntos}</div></div>
						<div className="puntuaje-subconts" ><div className="titulos-prueba">Html5</div><div className="cont-puntos puntos-web"  data-porcentaje="100%"> {puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">CSS 3</div><div className="cont-puntos puntos-web" data-porcentaje="100%"> {puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">JQuery</div><div className="cont-puntos puntos-web" data-porcentaje="100%"> {puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">PHP</div><div className="cont-puntos puntos-web" data-porcentaje="30%"> {puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">BootsStrap</div><div className="cont-puntos puntos-web" data-porcentaje="100%">{puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">Wordpress</div><div className="cont-puntos puntos-web" data-porcentaje="80%">{puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">SVG Animation</div><div className="cont-puntos puntos-web" data-porcentaje="100%">{puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">GSAP</div><div className="cont-puntos puntos-web" data-porcentaje="100%">{puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">{t("Agile methodologies")}</div><div className="cont-puntos puntos-web" data-porcentaje="80%">{puntos}</div></div>
					</div>
				</div>
				<div className="habilidades-sub-cont">
					<div className="exp-headers-cont">
						<div className="exp-header-elements"><img className="marcador" alt="" src={Marcador}></img></div>
						<div className="exp-header-elements">{t("Design")}</div>
					</div>
					<div className="puntuaje">
						<div className="puntuaje-subconts"><div className="titulos-prueba">Figma</div><div className="cont-puntos puntos-design" data-porcentaje="100%">{puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">Photoshop</div><div className="cont-puntos puntos-design" data-porcentaje="100%">{puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">Illustrator</div><div className="cont-puntos puntos-design" data-porcentaje="100%">{puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">Lightroom</div><div className="cont-puntos puntos-design" data-porcentaje="100%">{puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">Z-Brush</div><div className="cont-puntos puntos-design" data-porcentaje="60%">{puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">3d Studio Max</div><div className="cont-puntos puntos-design" data-porcentaje="35%">{puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">Keyshot</div><div className="cont-puntos puntos-design" data-porcentaje="80%">{puntos}</div></div>
					</div>
				</div>
				<div className="habilidades-sub-cont">
					<div className="exp-headers-cont">
						<div className="exp-header-elements"><img className="marcador" alt="" src={Marcador}></img></div>
						<div className="exp-header-elements audio-video">Audio/VIdeo</div>
					</div>
					<div className="puntuaje">
						<div className="puntuaje-subconts"><div className="titulos-prueba">After-Effects</div><div className="cont-puntos puntos-audio" data-porcentaje="100%">{puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">Premiere</div><div className="cont-puntos puntos-audio" data-porcentaje="70%">{puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">Final Cut Pro</div><div className="cont-puntos puntos-audio" data-porcentaje="30%">{puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">Pro-Tools</div><div className="cont-puntos puntos-audio" data-porcentaje="100%">{puntos}</div></div>
						<div className="puntuaje-subconts"> <div className="titulos-prueba">Nuendo/Cubase</div><div className="cont-puntos puntos-audio" data-porcentaje="100%">{puntos}</div></div>
						<div className="puntuaje-subconts"> <div className="titulos-prueba">Midi & VST</div><div className="cont-puntos puntos-audio" data-porcentaje="90%">{puntos}</div></div>
						<div className="puntuaje-subconts"><div className="titulos-prueba">Mixers YAMAHA</div><div className="cont-puntos puntos-audio" data-porcentaje="100%">{puntos}</div></div>
					</div>
				</div>
			</div>
			<div className="experiencia-cont">
				<div className="header-sections-experience">{t("Resume")}</div>
				<div className="experiencia-sub-cont">
					<div className="experiencia-sub-conts exp-profesional">
						<div className="contenedor-center">
							<div className="exp-headers-cont">
								<div className="exp-header-elements"><img className="marcador" alt="" src={Marcador}></img></div>
								<div className="exp-header-elements">{t("EXPERIENCE")}</div>
								<div className="exp-header-elements" ><img className="icos-experience" alt="" src={IcoExperience}></img></div>
							</div>
							<div className="exp-info-cont">
								{/* Arrow */}
								<div className="exp-info-subconts start">
											<div className="exp-info exp-info-fecha"></div>
											<div className='exp-info-fecha-line start'></div>
											<div className="exp-info exp-info-info">
												<p className="exp-title">
													<b></b>
												</p>
											</div>
										</div>
									{/* Arrow */}
								<div className="exp-info-subconts">
									<div className="exp-info exp-info-fecha">2023-2024</div>
									<div className='exp-info-fecha-line'></div>
									<div className="exp-info exp-info-info">
										<p className="exp-title">
											<b>PlatformOs</b>
											<span><a href="https://www.platformos.com/" target="blank">www.platformos.com</a></span>
										</p>
										<p></p>
										<p>{t("PlatformOs1")}</p>
										<p>{t("PlatformOs2")}</p>
									</div>
								</div>
								<div className="exp-info-subconts">
									<div className="exp-info exp-info-fecha">Half 2023</div>
									<div className='exp-info-fecha-line'></div>
									<div className="exp-info exp-info-info">
										<p className="exp-title">
											<b>GreenMonks</b>
										</p>
										<p>{t("GreenMonks1")}</p>
										<p>{t("GreenMonks2")}</p>
									</div>
								</div>
								<div className="exp-info-subconts">
									<div className="exp-info exp-info-fecha">2020-2023</div>
									<div className='exp-info-fecha-line'></div>
									<div className="exp-info exp-info-info">
										<p className="exp-title">
											<b>Vidoomy</b>
											<span><a href="https://www.vidoomy.com/" target="blank">www.vidoomy.com</a></span>
										</p>
										<p>{t("Vidoomy1")}</p>
										<p>{t("Vidoomy2")}</p>
									</div>
								</div>
								<div className="exp-info-subconts">
									<div className="exp-info exp-info-fecha">2019-2020</div>
									<div className='exp-info-fecha-line'></div>
									<div className="exp-info exp-info-info">
										<p className="exp-title">
											<b >Customily</b>
											<span><a href="https://www.customily.com/" target="blank">www.customily.com</a></span>
										</p>
										<p>{t("Customily1")}</p>
										<p>{t("Customily2")}</p>
									</div>
								</div>
								<div className="exp-info-subconts">
									<div className="exp-info exp-info-fecha">2016-2019</div>
									<div className='exp-info-fecha-line'></div>
									<div className="exp-info exp-info-info">
										<p className="exp-title">
											<b>Digital Invicta</b>
											<span><a href="https://www.digitalinvicta.com" target="blank">www.digitalinvicta.com</a></span>
										</p>
										<p>{t("Development of websites, web-apps, landing pages,animated ads, email marketing, video edition")}</p>
									</div>
								</div>
								<div className="exp-info-subconts">
									<div className="exp-info exp-info-fecha">2014-2016</div>
									<div className='exp-info-fecha-line'></div>
									<div className="exp-info exp-info-info">
										<p className="exp-title">
											<b>Asymmetry Studio</b>
											<span><a href="https://www.asymmetrystudio.com" target="blank">www.asymmetrystudio.com</a></span>
										</p>
										<p>{t("Visual Designer, Ux, Graphic Design")}</p>
										<p>{t("Web, banners,photography")}</p>
									</div>
								</div>
								<div className="exp-info-subconts">
									<div className="exp-info exp-info-fecha">2013-2014</div>
									<div className='exp-info-fecha-line'></div>
									<div className="exp-info exp-info-info">
										<p className="exp-title">
											<b>Blimbo.es (Madrid)</b>
										</p>
										<p>{t("Ecommerce set up and management")}</p>
										<p>{t("Graphic Design, social media content design")}</p>
										<p>Photoshop, Illustrator, After Effects...</p>
									</div>
								</div>
								<div className="exp-info-subconts">
									<div className="exp-info exp-info-fecha">2006-2011</div>
									<div className='exp-info-fecha-line'></div>
									<div className="exp-info exp-info-info">
										<p className="exp-title">
											<b>Factoria de Video (Madrid)</b>
										</p>
										<p>{t("Audio Post-Production (Pro-Tools HD)")}</p>
										<p>{t("Mix and edit of advertising spots, documentary, cinema shorts, TV shows")}</p>
										<p>{t("Dubbing sessions for TV and radio commercial brakes")}</p>
									</div>
								</div>
								<div className="exp-info-subconts">
									<div className="exp-info exp-info-fecha">2005-2006</div>
									<div className='exp-info-fecha-line'></div>
									<div className="exp-info exp-info-info">
										<p className="exp-title">
											<b>Pink Noise (Madrid)</b>
										</p>
										<p>{t("Production, postproduction and recording at videogames dubbing sessions")}</p>
										<p>{t("(Works developed for Microsoft and Sony)")}</p>
									</div>
								</div>
								<div className="exp-info-subconts">
									<div className="exp-info exp-info-fecha">2004-2005</div>
									<div className='exp-info-fecha-line'></div>
									<div className="exp-info exp-info-info">
										<p className="exp-title">
											<b>Estudios Chitón (Madrid)</b>
										</p>
										<p>{t("Worked as assistant in musical recording sessions")}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="experiencia-sub-conts exp-education">
							<div className="contenedor-center">
								<div className="exp-headers-cont">
									<div className="exp-header-elements"><img className="marcador" alt="" src={Marcador}></img></div>
									<div className="exp-header-elements">{t("EDUCATION")}</div>
									<div className="exp-header-elements" ><img className="icos-experience" alt="" src={IcoEducation}></img></div>
								</div>
								<div className="exp-info-cont">
									{/* Arrow */}
										<div className="exp-info-subconts start">
											<div className="exp-info exp-info-fecha"></div>
											<div className='exp-info-fecha-line start'></div>
											<div className="exp-info exp-info-info">
												<p className="exp-title">
													<b></b>
												</p>
											</div>
										</div>
									{/* Arrow */}
								
									<div className="exp-info-subconts">
											<div className="exp-info exp-info-fecha">2019</div>
											<div className='exp-info-fecha-line'></div>
											<div className="exp-info exp-info-info">
												<p className="exp-title">
													<b>{t("Front-End Developer Course")}</b>
												</p>
												<p>CodeCademy</p>
										</div>
									</div>
									<div className="exp-info-subconts">
										<div className="exp-info exp-info-fecha">2001-2003</div>
										<div className='exp-info-fecha-line'></div>
										<div className="exp-info exp-info-info">
											<p className="exp-title">
												<b>{t("High Level Technician Sound")}</b>
											</p>
											<p>(Carlos Mª Rodríguez Valcárcel, Madrid)</p>
										</div>
									</div>
									<div className="exp-info-subconts">
										<div className="exp-info exp-info-fecha">2003-2004</div>
										<div className='exp-info-fecha-line'></div>
										<div className="exp-info exp-info-info">
											<p className="exp-title">
												<b>{t("Official Master on Musical mixing and recording")}</b>
											</p>
											<p>{t("(Microfusa ; Audio School, Madrid)")}</p>
										</div>
									</div>
								</div>

							<div className="exp-headers-cont">
								<div className="exp-header-elements"><img className="marcador" alt="" src={Marcador}></img></div>
								<div className="exp-header-elements">{t("LANGUAGES")}</div>
								<div className="exp-header-elements" ><img className="icos-experience" alt="" src={IcoLang}></img></div>
							</div>
							<div className="exp-info-cont">
								<div className="exp-info-subconts">
									
									<div className="exp-info exp-info-fecha langss">
									<img className="languages-icons" alt="" src={IcoEnglish}></img>
										{t("English")}</div>
									<img className="arrow-titles" alt="" src={arrowTitles}></img>
									<div className="exp-info exp-info-info">
										<b>Cambridge First Certificated 2011</b>
										<p>{t("Fluent Cambridge First Certificate 2011 1 year studing and working in Dublin")}</p>
									</div>
								</div>
								<div className="exp-info-subconts">
									
									<div className="exp-info exp-info-fecha langss">
										<img className="languages-icons" alt="" src={IcoSpanish}></img>
										{t("Spanish")}
									</div>
									<img className="arrow-titles" alt="" src={arrowTitles}></img>

									<div className="exp-info exp-info-info">
										<p>{t("Native")}</p>
									</div>
								</div>
							</div>
							<div className="exp-headers-cont">
								<div className="exp-header-elements"><img className="marcador" alt="" src={Marcador}></img></div>
								<div className="exp-header-elements">{t("INTERESTS")}</div>
								<div className="exp-header-elements" ><img className="icos-experience" alt="" src={IcoInterests}></img></div>
							</div>
							<div className="exp-info-subconts">
								<div className="interests">
									<span>{t("Electric guitar")}</span>
									<span>{t("Music")}</span>
									<span>{t("Digital Photography")}</span>
									<span>{t("Design")}</span>
									<span>{t("Cinema")}</span>
									<span>3d</span>
									<span>{t("Videogames")}</span>
									<span>{t("Urban cycling")}</span>
									<span>{t("Swimming")}</span>
									</div>
							</div>
						</div>
					</div>
				</div>
				<div className="resume-download-cont" onMouseOver={this.handleResumeDownload} onMouseOut={this.handleResumeDownloadOut}>
					<div className="download-text">&#8681; {t("Download")} PDF</div>
					<div className="resume-download-subcont">
						<p>{t("Mode")}</p>
						<a href="https://www.diegopiscitelli.com/downloads/Resume_Diego_Piscitelli_2024_EN.pdf">{t("Dark")}</a>
						<a href="https://www.diegopiscitelli.com/downloads/Resume_Diego_Piscitelli_2024_EN_W.pdf">{t("Light")}</a>
					</div>
				</div>
			</div>
    </div>
		)
	}
}


export default (withTranslation("translations")(Experience));