import React from 'react';
import { Router,Switch, Route } from 'react-router-dom';
import { withTranslation } from "react-i18next";

import { Transition, TransitionGroup } from 'react-transition-group';
import { play, exit, navSelector} from './timelines/timelines.js';
import './App.css';
import Loader from '../Loader/Loader';
import NavBar from '../NavBar/NavBar';
import Home from '../Views/Home';
import Experience from '../Views/Experience';
import Works from '../Views/Works';
import Contact from '../Views/Contact';

import { TimelineMax as Timeline, Power1} from 'gsap';

///////////////////ANALITICS
import { createBrowserHistory } from "history";
import ReactGA from 'react-ga';

//WORKS/////////////////////////////////////////////////////////////////////////////////////
import Vidoomy from '../Works/Vidoomy';
import Guitar from '../Works/Guitar';
import StoryDots from '../Works/StoryDots';
import DigitalInvicta from '../Works/DigitalInvicta';
import SevenAttitudes from '../Works/SevenAttitudes';
import Asymmetry from '../Works/Asymmetry';
import Vlp from '../Works/Vlp';
import Batman from '../Works/Batman';
import Scorpion from '../Works/Scorpion';
import Concept from '../Works/Concept';
import Logos from '../Works/Logos';
import Photo from '../Works/Photo';


import TransitionSvg from '../TransitionSvg/TransitionSvg';

//IMGS PRELOAD/////////////////////////////////////////////////////////////////////////////////////
import Vidoomy_00 from '../Media/img/projects/vidoomy_00.png';
import Guitar_00 from '../Media/img/projects/guitar_00.jpg';
import DigitalInvicta_00 from '../Media/img/projects/digitalInvicta_00.jpg';
import SevenAttitudes_00 from '../Media/img/projects/sevenAttitudes_00.jpg';
import Asymmetry_00 from '../Media/img/projects/asymmetry_00.jpg';
import Vlp_00 from '../Media/img/projects/vlp_00.jpg';
import Storydots_00 from '../Media/img/projects/storyDots_00.jpg';
import Logos_00 from '../Media/img/projects/logos/logo_cover2.jpg';
import Batman_01 from '../Media/img/projects/batman_01.jpg';
import Scorpion_01 from '../Media/img/projects/scorpion_01.jpg';
import Comic_scfi_00 from '../Media/img/projects/comic_scfi_00.jpg';
import Photo_00 from '../Media/img/photo/photo_08.jpg';
import ComingSoon from '../Media/img/projects/comingSoon.jpg';
import Fondo_contacto from '../Media/img/contacto2.jpg';

const history = createBrowserHistory();
history.listen(location => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
});


var loadedImg = 0;

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			section: undefined,
			mobile: undefined,
			width: 0,
			height: 0,
			x: 0,
			y: 0,
			selectedWork: undefined,
			lang: "en",
			scrolled: false
		}
		this.handleLangEvent = this.handleLangEvent.bind(this);
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		this.handleClickNav = this.handleClickNav.bind(this);
		this.handleClickNavResponsive = this.handleClickNavResponsive.bind(this);
		this.loader = this.loader.bind(this);
		this.onloadImagesTrue = this.onloadImagesTrue.bind(this);
		this.handleScrollMethod = this.handleScrollMethod.bind(this);

		history.listen(location => {
			if (location.pathname.includes("works")) {
				this.setState( {section:"Works"} );
			} else if (location.pathname.includes("experience")||location.pathname.includes("contact")) {
				const urlArray = window.location.pathname.split('/')[1].charAt(0).toUpperCase() + window.location.pathname.split('/')[1].slice(1);
				this.setState({section:urlArray});
			} else {
				this.setState({section:"Home"});
			}
			setTimeout(
				function() {
					this.handleClickNav(this.state.section)
				}
				.bind(this),
				100
			);
		})
		this.myRef = React.createRef();
	}

	componentDidMount() {
		ReactGA.pageview(window.location.pathname)

		if (document.URL.includes("works")) {
			this.setState( {section:"Works"} );
		} else if (document.URL.includes("experience")||document.URL.includes("contact")) {
			const urlArray = window.location.pathname.split('/')[1].charAt(0).toUpperCase() + window.location.pathname.split('/')[1].slice(1);
			this.setState({section:urlArray});
		} else {
			this.setState({section:"Home"});
		}
		setTimeout(
			function() {
				this.updateWindowDimensions()
			}.bind(this),
			500
		);

		const scrollContainer = this.myRef.current.querySelector('#scroll-container');
		window.addEventListener('resize',this.updateWindowDimensions);
		scrollContainer.addEventListener('scroll',this.handleScrollMethod);
	}

	handleScrollMethod(e) {
		const scrollOffset = e.target.scrollTop;
		if (scrollOffset > 50) {
			this.setState({scrolled: true})
		} else {
			this.setState({scrolled: false})
		}
	}

	onloadImagesTrue() {
		const loadArray = this.myRef.current.querySelectorAll('.img-load'); 
		loadedImg ++
		if(loadedImg === loadArray.length){
			this.setState({loading:false})
			setTimeout(
				function() {
					this.loader();
				}
				.bind(this),
				100
			);
		}
	}

	loader() {
		const loader = this.myRef.current.querySelector('.cont-loader');
		if( this.state.loading === true){
			const timeline = new Timeline({ paused: true});
			timeline.to(loader, 0.5, {opacity:1})
			timeline.play();
		}
		else if(this.state.loading === false){
			const timeline = new Timeline({ paused: true});
			timeline.to(loader, 0.5, {opacity:0})
			timeline.play();
		}
	}

updateWindowDimensions() {
	this.setState({ width: window.innerWidth, height: window.innerHeight,mobile:false});
	if(this.state.width > 1025){
		setTimeout(
			function() {
				this.handleClickNav(this.state.section)
			}.bind(this),
			500
		);
	}
}

componentWillUnmount() {
	window.removeEventListener('resize', this.updateWindowDimensions);
}

handleClickNav (label) {
	this.setState({section:label})
	if(this.state.width > 1025){
		setTimeout(() => {
			const posicionButtton = this.myRef.current.querySelector('.'+this.state.section).offsetLeft-15;
			const posicionButttonY = this.myRef.current.querySelector('.'+this.state.section).offsetTop-3;
			const widthButtton = this.myRef.current.querySelector('.'+this.state.section).offsetWidth+15;
			const selector = this.myRef.current.querySelector('.nav-pointer');
			const selectL = this.myRef.current.querySelector('.select-l');
			const selectR = this.myRef.current.querySelector('.select-r');
			navSelector(posicionButtton ,posicionButttonY , widthButtton, selector,selectL,selectR);
		}, 600)
	}
}

handleClickNavResponsive(label){
	const timeline = new Timeline({ paused: true });
	let mobile = this.state.mobile;
	const nav = this.myRef.current.querySelector('.nav-resp');
	const linea_a = this.myRef.current.querySelector('#linea_a');
	const linea_b = this.myRef.current.querySelector('#linea_b');
	const linea_c = this.myRef.current.querySelector('#linea_c');
	this.setState({section:label})

	if (mobile === false) {
		nav.style.display ="block";
		timeline.to(nav, 0.5, {opacity:1,ease: Power1.easeInOut})
		
		.to(linea_a,0.5,{attr: { y2: 24.7 },ease: Power1.easeInOut},0)
		.to(linea_b,0.5,{opacity:0,ease: Power1.easeInOut},0)
		.to(linea_c,0.5,{attr: { y2: 5.3 },ease: Power1.easeInOut},0);
		
		timeline.play();
		this.setState({mobile:true})
	} else if (mobile === true) {
		timeline.to(nav, 0.5, {opacity:0,ease: Power1.easeInOut,delay:0.1})
		.to(linea_c,0.5,{attr: { y2: 24.7 },ease: Power1.easeInOut},0)
		.to(linea_b,0.5,{opacity:1,ease: Power1.easeInOut},0)
		.to(linea_a,0.5,{attr: { y2: 5.3 },ease: Power1.easeInOut},0)
		.to(nav,0,{display:"none"});
		timeline.play();
		this.setState({mobile:false})
	}
}


handleHoversEventsScroll(e){
	const TweenMax = new Timeline();
	const widthE = e.offsetLeft;
	TweenMax.to("h1",1,{width:widthE});
}

handleLangEvent(lang) {
		let newlang = lang;
		this.setState(prevState => ({ lang: newlang }));
		this.props.i18n.changeLanguage(newlang);
		setTimeout(
			function() {
				this.handleClickNav(this.state.section)
			}
			.bind(this),
			100
		);
	};

	render() {  
		const {width, mobile, lang, scrolled} = this.state; 

		return (

			<Router  history={history}>
				<div className="app" ref={this.myRef}>
					<Loader loading={this.state.loading}/> 
					<NavBar
						lang= {lang}
						section= {this.state.section} 
						screenWidth= {width}
						mobile= {mobile}
						needBackgorund= {scrolled}
						handleNavResp= {this.handleClickNavResponsive}
						handleLangEvent= {this.handleLangEvent}
						handleClickNav= {this.handleClickNav.bind(this)}>
					</NavBar>
					<div id="scroll-container" className='scroll-container-class' >
						<TransitionSvg section={this.state.section}></TransitionSvg>
						<Route render={ ({ location }) => {
							const { pathname, key } = location;
							return (
								<TransitionGroup component={null}>
									<Transition
										key={key}
										appear={true}
										onEnter={(node, appears) => play(pathname, node, appears)}
										onExit={(node, appears) => exit(node, appears,mobile)}
										timeout={{enter: 0, exit:1000}}
									>
										<Switch location={location}>
											<Route exact path="/" render={(props) => <Home  lang={this.state.lang} handleClickNav={this.handleClickNav.bind(this)} />}/>
											<Route path="/experience" component={Experience} />
											<Route path="/works" render={(props) => <Works lang={this.state.lang} mobile={width}/>} />
											<Route path="/contact" component={Contact} />
											<Route path="/vidoomy-project" render={(props) => <Vidoomy category="web" index="0"/>} />
											<Route path="/guitar-project" render={(props) => <Guitar category="web" index="1"/>} />
											<Route path="/digitalinvicta-project" render={(props) => <DigitalInvicta category="web" index="2"/>} />
											<Route path="/sevenattitudes-project" render={(props) => <SevenAttitudes category="web" index="3"/>} />
											<Route path="/asymmetrystudio-project" render={(props) => <Asymmetry category="web" index="4"/>} />
											<Route path="/storydots-project" render={(props) => <StoryDots category="web" index="5"/>} />
											<Route path="/villalaprovence-project" render={(props) => <Vlp category="web" index="6"/>} />

											<Route path="/batman-project" render={(props) => <Batman category="design" index="0" />} />
											<Route path="/scorpion-project" render={(props) => <Scorpion category="design" index="1"/>} />
											<Route path="/concept-project" render={(props) => <Concept category="design" index="2"/>} />
											<Route path="/logos-project" render={(props) => <Logos category="design" index="3"/>} />
											<Route path="/photo-project" render={(props) => <Photo category="photo" index="0"/>} />
										</Switch>
									</Transition>
								</TransitionGroup>
							)
						}}/>
					</div>
					<div className="carga-img" onLoad={this.onloadImagesTrue}>
						<img  className="img-load" alt="" src={Vidoomy_00}></img>
						<img  className="img-load" alt="" src={Guitar_00}></img>
						<img  className="img-load" alt="" src={DigitalInvicta_00}></img>
						<img  className="img-load" alt="" src={SevenAttitudes_00}></img>
						<img  className="img-load" alt="" src={Asymmetry_00}></img>
						<img  className="img-load" alt="" src={Vlp_00}></img>
						<img  className="img-load" alt="" src={Storydots_00}></img>
						<img  className="img-load" alt="" src={Batman_01}></img>
						<img  className="img-load" alt="" src={Scorpion_01}></img>
						<img  className="img-load" alt="" src={Comic_scfi_00}></img>
						<img  className="img-load" alt="" src={ComingSoon}></img>
						<img  className="img-load" alt="" src={Fondo_contacto}></img>
						<img  className="img-load" alt="" src={Logos_00}></img>
						<img  className="img-load" alt="" src={Photo_00}></img>
					</div>
				</div>
			</Router>
		)
	}
}

export default (withTranslation("translations")(App));




								