
import DigitalInvicta_00 from '../Media/img/projects/digitalInvicta_00.jpg';
import DigitalInvicta_01 from '../Media/img/projects/digitalInvicta_01.jpg';
import DigitalInvicta_02 from '../Media/img/projects/digitalInvicta_02.jpg';
import DigitalInvicta_03 from '../Media/img/projects/digitalInvicta_03.jpg';
import DigitalInvicta_04 from '../Media/img/projects/digitalInvicta_04.jpg';
import DigitalInvicta_gif_01 from '../Media/img/projects/digitalInvicta_gif_01.gif';
import DigitalInvicta_gif_02 from '../Media/img/projects/digitalInvicta_gif_02.gif';

import SevenAttitudes_00 from '../Media/img/projects/sevenAttitudes_00.jpg';
import SevenAttitudes_01 from '../Media/img/projects/sevenAttitudes_01.jpg';
import SevenAttitudes_02 from '../Media/img/projects/sevenAttitudes_02.jpg';
import SevenAttitudes_03 from '../Media/img/projects/sevenAttitudes_03.jpg';
import SevenAttitudes_video from '../Media/img/projects/sevenAttitudes.mp4';

import Asymmetry_00 from '../Media/img/projects/asymmetry_00.jpg';
import Asymmetry_01 from '../Media/img/projects/asymmetry_01.jpg';
import Asymmetry_02 from '../Media/img/projects/asymmetry_02.jpg';
import Asymmetry_03 from '../Media/img/projects/asymmetry_03.jpg';
import Asymmetry_04 from '../Media/img/projects/asymmetry_04.jpg';

import Vlp_00 from '../Media/img/projects/vlp_00.jpg';
import Vlp_01 from '../Media/img/projects/vlp_01.jpg';
import Vlp_02 from '../Media/img/projects/vlp_02.jpg';
import Vlp_03 from '../Media/img/projects/vlp_03.jpg';
import Vlp_04 from '../Media/img/projects/vlp_04.jpg';
import Vlp_05 from '../Media/img/projects/vlp_05.jpg';

import StoryDots_00 from '../Media/img/projects/storyDots_00.jpg';
import StoryDots_01 from '../Media/img/projects/storyDots_01.jpg';
import StoryDots_02 from '../Media/img/projects/storyDots_02.jpg';

import Guitar_00 from '../Media/img/projects/guitar_00.jpg';
import Guitar_01 from '../Media/img/projects/guitar_01.jpg';
import Guitar_02 from '../Media/img/projects/guitar_02.jpg';
import Guitar_03 from '../Media/img/projects/guitar_03.jpg';
import Guitar_04 from '../Media/img/projects/guitar_04.jpg';
import Guitar_05 from '../Media/img/projects/guitar_05.jpg';
import Guitar_06 from '../Media/img/projects/guitar_06.jpg';
import Guitar_07 from '../Media/img/projects/guitar_07.jpg';

import Vidoomy_00 from '../Media/img/projects/vidoomy_00.png';
import Vidoomy_01 from '../Media/img/projects/vidoomy_01.png';
import Vidoomy_02 from '../Media/img/projects/vidoomy_02.png';
import Vidoomy_03 from '../Media/img/projects/vidoomy_03.png';
import Vidoomy_04 from '../Media/img/projects/vidoomy_04.png';
import Vidoomy_05 from '../Media/img/projects/vidoomy_05.png';
import Vidoomy_06 from '../Media/img/projects/vidoomy_06.png';
import Vidoomy_07 from '../Media/img/projects/vidoomy_07.png';

import Logos_00 from '../Media/img/projects/logos/logo_cover2.jpg';
import Logos_01 from '../Media/img/projects/logos/logo_blimbo.svg';
import Logos_02 from '../Media/img/projects/logos/logo_cv.svg';
import Logos_03 from '../Media/img/projects/logos/logo_7a.gif';
import Logos_04 from '../Media/img/projects/logos/logo_jutzer.svg';
import Logos_05 from '../Media/img/projects/logos/logo_digitalinvicta.gif';
import Logos_06 from '../Media/img/projects/logos/logo_bonavena.svg';
import Logos_07 from '../Media/img/projects/logos/logo_bossa.jpg';
import Logos_08 from '../Media/img/projects/logos/logo_asymmetry.svg';
import Logos_09 from '../Media/img/projects/logos/logo_melyoga.svg';
import Logos_10 from '../Media/img/projects/logos/logo_santarosa.svg';
import Logos_11 from '../Media/img/projects/logos/logo_mystic.gif';
import Logos_12 from '../Media/img/projects/logos/logo_omystic.gif';

import Batman_01 from '../Media/img/projects/batman_01.jpg';
import Scorpion_01 from '../Media/img/projects/scorpion_01.jpg';
import Scorpion_02 from '../Media/img/projects/scorpion_02.jpg';
import Scorpion_03 from '../Media/img/projects/scorpion_03.jpg';
import Scorpion_04 from '../Media/img/projects/scorpion_04.jpg';
import Comic_scfi_00 from '../Media/img/projects/comic_scfi_00.jpg';
import Comic_scfi_01 from '../Media/img/projects/comic_scfi_01.jpg';

// import ComingSoon from '../Media/img/projects/comingSoon.jpg';

import Photo_00 from '../Media/img/photo/photo_08.jpg';
import Photo_01 from '../Media/img/photo/photo_01.jpg';
import Photo_02 from '../Media/img/photo/photo_02.jpg';
import Photo_03 from '../Media/img/photo/photo_03.jpg';
import Photo_04 from '../Media/img/photo/photo_04.jpg';
import Photo_05 from '../Media/img/photo/photo_05.jpg';
import Photo_06 from '../Media/img/photo/photo_06.jpg';
import Photo_07 from '../Media/img/photo/photo_07.jpg';
import Photo_08 from '../Media/img/photo/photo_08.jpg';
import Photo_09 from '../Media/img/photo/photo_09.jpg';
import Photo_10 from '../Media/img/photo/photo_10.jpg';
import Photo_11 from '../Media/img/photo/photo_11.jpg';
import Photo_12 from '../Media/img/photo/photo_12.jpg';
import Photo_13 from '../Media/img/photo/photo_13.jpg';
import Photo_14 from '../Media/img/photo/photo_14.jpg';

const data = {
	"web": [
		{
			"index":0,
			"id": "vidoomy",
			"header": "Vidoomy",
			"imagen":Vidoomy_00,
			"imgCabecera":Vidoomy_01,
			"descripcion":"Vidoomy Marketing Platform",
			"expand":true,
			"expandImgArray":[Vidoomy_02,Vidoomy_03,Vidoomy_04,Vidoomy_05, Vidoomy_06, Vidoomy_07]
		},
		{
			"index":1,
			"id": "guitar",
			"header": "Guitar Customizer",
			"imagen":Guitar_00,
			"imgCabecera":Guitar_01,
			"descripcion":"App Web",
			"expand":true,
			"expandImgArray":[Guitar_02,Guitar_03,Guitar_04,Guitar_05,Guitar_06,Guitar_07]
		},
		{
			"index":2,
			"id": "digitalinvicta",
			"header": "Digital Invicta",
			"imagen":DigitalInvicta_00 ,
			"imgCabecera":DigitalInvicta_01,
			"descripcion":"Website",
			"expand":true,
			"expandImgArray":[DigitalInvicta_02,DigitalInvicta_03,DigitalInvicta_gif_02,DigitalInvicta_gif_01,DigitalInvicta_04]
		},
		{
			"index":3,
			"id": "sevenattitudes",
			"header": "7Attitudes",
			"imagen":SevenAttitudes_00 ,
			"descripcion":"Website",
			"video":SevenAttitudes_video,
			"expand":true,
			"expandImgArray":[SevenAttitudes_01,SevenAttitudes_02,SevenAttitudes_03]
		},
		{
			"index":4,
			"id": "asymmetrystudio",
			"header": "Asymmetry Studio",
			"imagen":Asymmetry_00 ,
			"imgCabecera":Asymmetry_01,
			"descripcion":"Ux-Animations",
			"expand":true,
			"expandImgArray":[Asymmetry_02,Asymmetry_03,Asymmetry_04]
		},
		{
			"index":5,
			"id": "storydots",
			"header": "StoryDots",
			"imagen":StoryDots_00 ,
			"imgCabecera":StoryDots_00,
			"descripcion":"Website",
			"expand":true,
			"expandImgArray":[StoryDots_01,StoryDots_02]
		},
		{
			"index":6,
			"id": "villalaprovence",
			"header": "villa la Provence",
			"imagen":Vlp_00,
			"imgCabecera":Vlp_01,
			"descripcion":"Website",
			"expand":true,
			"expandImgArray":[Vlp_02,Vlp_03,Vlp_04,Vlp_05]
		},
		
	],

	"design": [
		{
			"index":0,
			"id": "batman",
			"header": "Batman",
			"imagen":Batman_01 ,
			"descripcion":"Digital Painting",
			"expand":true,
			"expandImgArray":[Batman_01]
		},
		{
			"index":1,
			"id": "scorpion",
			"header": "Scorpion",
			"imagen":Scorpion_01 ,
			"descripcion":"3d modelling",
			"expand":true,
			"expandImgArray":[Scorpion_01,Scorpion_02,Scorpion_03,Scorpion_04]
		},
		{
			"index":2,
			"id": "concept",
			"header": "Concept",
			"imagen":Comic_scfi_00 ,
			"descripcion":"Concept art",
			"expand":true,
			"expandImgArray":[Comic_scfi_00,Comic_scfi_01 ]
		},
		{
			"index":3,
			"id": "logos",
			"header": "Logotypes",
			"imagen":Logos_00,
			"descripcion":"Several logotypes",
			"expand":true,
			"expandImgArray":[Logos_01,Logos_02,Logos_03,Logos_04,Logos_05,Logos_06,Logos_07,Logos_08,Logos_09,Logos_10,Logos_11,Logos_12 ]
		}
	],

	"photo": [
		{
			"index":0,
			"id": "photo",
			"header": "Photo Portfolio",
			"imagen": Photo_00,
			"descripcion":"Selection of pictures shooted over the years",
			"expand":true,
			"expandImgArray":[Photo_01,Photo_02,Photo_03,Photo_04,Photo_05,Photo_06,Photo_07,Photo_08,Photo_09,Photo_10,Photo_11,Photo_12,Photo_13,Photo_14]
		}
	],/*

	"personal": [
	{   "index":0,
	"id": "personal2",
	"header": "",
	"imagen": ComingSoon,
	"descripcion":"",
	"expand":false
	}

	]*/
}

export default data;