import React from 'react';
import './projects.css'
import data from '../data/data'
import { NavLink } from 'react-router-dom';
import { withTranslation } from "react-i18next";


class Batman extends React.Component {


render(){
const { t } = this.props;
const category = this.props.category;
const index = this.props.index;
const project = data[category][index];

const galery = project.expandImgArray.map(item => <div key={item} className="cont-img-galery"><img src={"../" + item} alt="Batman" className="img-galery"></img></div> );
  
	return (
		<div className="work-page-cont Batman">
    <NavLink className="work-page-button "  strict exact to={"/works?category="+category +"&project="+index} >{t("Back")}</NavLink>
      
        <div className="work-page-subcont dark">
        <div className="headers-work-page">{project.header}</div>
        <img className="main-image" src={"../" + project.imagen} alt="Batman"></img>
        </div>

        <div className="work-page-project dark">

              <div className="project-header-cont">
                    <div className="project-header-subcont">
                        <p className="project-header">{t("Project")}</p>
                        <p className="project-description-text">Digital Painting</p>
                    </div>

                     <div className="large-description">{t("Digital painting for nacional comic contest 2019")}

              </div>
              </div>

              <div className="project-bullets">
                  <div className="project-bullets-header">{t("Tools")}:</div>
                   <ul>
                        <li className='especial-bullets'>Digital Painting</li>
                        <li className='especial-bullets'>Photoshop</li>
                  </ul>
              </div>

             

        </div>

        <div className="work-page-galery dark" >

         {galery}

        </div>
       <NavLink className="work-page-button "  strict exact to={"/works?category="+category +"&project="+index} >{t("Back")}</NavLink>
      </div>
		)
}
}


export default (withTranslation("translations")(Batman));