import React from 'react';
class TransitionSvg extends React.Component{
	render() {
		return (
			<svg id="transition" style={{opacity:1}} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="100%"  >
				<title>transition</title>
				<linearGradient id="my-cool-gradient" x2="0" y2="1">
					<stop offset="0%" stopColor="#0E1117" />
					<stop offset="100%" stopColor="#174045" />
				</linearGradient>
				<rect  id="trans0"   width="0%" height="100%" fill="url(#my-cool-gradient)"></rect>
				<line id="trans1"   x1="0" y1="50%" x2="0" y2="50%" stroke="#81edfc" strokeWidth="2"> </line>
				<text
					id="textSvg"
					x="30%"
					y="48%" 
					fontFamily="aileronsregular" 
					stroke="none"
					fill="#60A7B1"
					letterSpacing="30"
					opacity="0">
						{this.props.section}
				</text>
			</svg>
		)
	}
}

export default TransitionSvg;